<template>
    <div class="card_list flex_start">
        <el-card class="warehouse_ward column_between_start" v-for="(item,index) in wareHouseList" :key="item.id">
            <div>{{item.warehouse_name}}</div>
            <div class="to_txt" @click.stop="toBooking(item)">点击前往</div>
        </el-card>
    </div>
</template>
<script>
import {handleGetWarehouseList} from '@api/booking'
export default {
    name:"booking",
    data() {
        return {
            page:1,
            wareHouseList:[],
            count:0
        }
    },
    created() {
        this.onGetWarehouseList()
    },
    mounted() {

    },
    computed:{
        userData:function(){
            return this.$store.getters.userData || this.$storage.session.get('userData')
        },
    },
    methods: {
        changeNav(evt) {
            this.$refs['car'].setActiveItem(evt)
        },
        toBooking(data){
            let item = data;
            if(item.url && item.url != ''){
                window.open(item.url)
            }else{
                this.$notify.warning({
                    title:'提示',
                    message:'该仓暂不可预约'
                })
                return;
            }
        },
        onClick(){
            this.$store.commit('SET_LOCTYPE','start')
            this.$router.push({path:'/index/mapChoice'})
        },
        onGetWarehouseList(){
            let datakey = {};
            datakey['page'] = this.page;
            handleGetWarehouseList(datakey).then(datas=>{
                console.log("dats===>",datas)
                if(datas.status == 0){
                    this.count = datas.data.count;
                    this.wareHouseList = datas.data.list;
                }
            }).catch(err=>{
                console.log(err)
            })
        }
    },
}
</script>
<style lang="scss">
    .card_list {
        flex-wrap: wrap;
        padding-left: 20px;
        padding-top: 16px;
    }
    
    .warehouse_ward {
        width: calc(25% - 20px);
        height: 160px;
        background: #f2f2f2;
        margin-right: 20px;
        margin-bottom: 20px;
    }
    
    .warehouse_ward .el-card__body {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }
    
    .warehouse_ward:nth-child(4n + 4) {
        margin-right: 0;
    }
    
    .to_txt {
        color: #02a7f0;
        align-self: flex-end;
        font-size: 14px;
        cursor: pointer;
    }
    
    .to_txt:hover {
        text-decoration: underline;
    }
</style>